<template>
  <div v-if="!isLoading">
    <Back to="/eir-sets">Назад</Back>

    <header class="d-flex mb-3 mb-md-0 flex-column flex-md-row">
      <div class="mb-2 mb-md-0 mr-auto">
        <div class="d-flex align-top">
          <h1 style="max-width: 700px">{{ eirSetInfo.name }}</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                text
                icon
                small
                color="primary"
                class="ml-2 mt-1"
                v-on="on"
                @click="$refs.modalEirSet.show(eirSetInfo, 'update', false)"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>Изменить</span>
          </v-tooltip>
          <CopySetButton
            :id="eirSetInfo.id"
            class="ml-2 mt-1"
            :name="eirSetInfo.name"
            :tooltip-props="{ top: true }"
            :btn-props="{ color: 'primary' }"
            :icon-props="{ size: 19 }"
          />
        </div>
        <div class="d-flex flex-row align-center">
          <span class="caption mr-2">Набор информационных требований</span>
          <v-btn
            v-if="!checkIsPublished"
            x-small
            class="elevation-10 mr-1 pa-3"
            color="primary"
            :disabled="!eirSetInfo.check_eir_filters"
            @click.stop="$refs.modalPublishingASet.show(eirSetInfo.id)"
          >
            <v-icon small class="mr-1">mdi-publish</v-icon>
            Опубликовать
          </v-btn>
          <v-chip v-else small label outlined color="primary" class="mr-1">
            Опубликован
          </v-chip>

          <v-menu
            v-if="eirSetInfo.versions?.[0]?.version"
            v-model="showVersions"
            open-on-hover
            transition="slide-y-transition"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-if="eirSetInfo.versions?.[0]?.version"
                v-bind="attrs"
                small
                label
                outlined
                color="primary"
                class="mr-1 pr-1 pl-2"
                v-on="on"
                @click.native.stop
              >
                <span class="font-weight-bold">Версия:&nbsp;</span>
                {{ eirSetInfo.versions?.[0]?.version }}
                <v-icon color="primary" class="ml-1" small>
                  mdi-menu-down
                </v-icon>
              </v-chip>
            </template>

            <v-card max-height="400">
              <v-list dense>
                <template
                  v-for="(
                    { id: versionId, version, updated_at: updatedAt, comment },
                    index
                  ) in eirSetInfo.versions"
                >
                  <v-divider v-if="index % 2 !== 0" :key="index" inset />
                  <v-list-item
                    :key="`version-${versionId}`"
                    :title="version"
                    :to="
                      index + 1 !== eirSetInfo.versions?.length
                        ? `/eir-sets/${$route.params.setId}/versions/${versionId}`
                        : null
                    "
                    append
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ version | truncate(40) }}&nbsp;
                        <span class="caption">
                          ({{ updatedAt | formatDate }})
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="caption" :title="comment">
                          {{ comment | truncate(40) }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </v-menu>

          <v-chip small label outlined color="primary" class="mr-1">
            <span class="font-weight-bold">Изменен:&nbsp;</span>
            {{ eirSetInfo.updated_at | formatDate }}
          </v-chip>
        </div>
      </div>
      <v-spacer />
      <SelectSections
        class="ml-0 ml-md-3"
        style="max-width: 550px; min-width: 300px"
        option-key="section__in"
        option-key-default="eir_set"
        :data-default="$route.params.setId"
        @input="fetchRelatedToSections"
      />
    </header>

    <div v-if="eirSetInfo.description" class="my-3">
      {{ eirSetInfo.description }}
    </div>

    <div class="d-sm-flex align-sm-end" style="min-height: 54px">
      <div class="order-sm-1 mb-sm-1 mr-2">
        <v-btn
          v-if="!showImportField"
          large
          elevation="10"
          color="primary"
          @click.stop="() => (showImportField = true)"
        >
          <v-icon small class="mr-1">mdi-table-arrow-left</v-icon>
          Импортировать
        </v-btn>
        <div v-else class="d-flex align-center">
          <v-file-input
            v-model="fileForImport"
            outlined
            dense
            style="width: 218px"
            hide-details
            :prepend-icon="null"
            prepend-inner-icon="mdi-table-arrow-left"
            append-icon="mdi-close"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="Импортировать"
            @click:append.stop="() => (showImportField = false)"
            @change="importSet"
          />
          <v-btn
            text
            small
            color="primary"
            class="px-0 ml-1"
            download
            href="/requirements_and_parameters_template.xlsx"
          >
            <v-icon class="mr-1" size="20">
              mdi-file-document-check-outline
            </v-icon>
            Шаблон импорта
          </v-btn>
        </div>
      </div>

      <div class="order-sm-2 mb-sm-1">
        <v-btn
          v-if="(isAdmin || isManager) && tab === 0"
          large
          color="primary"
          elevation="10"
          @click="$refs.modal.show()"
        >
          <v-icon class="mr-1" size="18">mdi-plus</v-icon>
          Требование
        </v-btn>

        <v-btn
          v-if="(isAdmin || isManager) && tab === 1"
          large
          color="primary"
          elevation="10"
          @click="$refs.modalParameter.show()"
        >
          <v-icon class="mr-1" size="18">mdi-plus</v-icon>
          Параметр
        </v-btn>
      </div>

      <v-tabs ref="tabs" v-model="tab" class="tabs order-sm-0">
        <v-tab>Требования ({{ totalElements }})</v-tab>
        <v-tab>Параметры набора ({{ totalParams }})</v-tab>
      </v-tabs>
    </div>

    <v-divider class="mb-3" />

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <TabEirs ref="tabEirs" @update="updateEirElement" />
      </v-tab-item>
      <v-tab-item>
        <TabParameters
          ref="tabParameters"
          @updated="() => fetchSet($route.params.setId)"
        />
      </v-tab-item>
    </v-tabs-items>

    <ModalParameter
      ref="modalParameter"
      @updated="() => fetchSet($route.params.setId)"
    />
    <ModalEir ref="modal" @updated="() => fetchSet($route.params.setId)" />
    <ModalEirSet ref="modalEirSet" />
    <ModalPublishingASet
      ref="modalPublishingASet"
      :after-publication="fetchSet"
      :args-after-fn="[$route.params.setId]"
    />
  </div>
  <v-progress-linear v-else color="primary" indeterminate />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import Back from '@/components/Back'

import ModalEir from './partials/ModalEir'
import TabEirs from './partials/TabEirs'

import TabParameters from '@/components/TabParameters'
import ModalParameter from '@/components/ModalParameter'
import ModalEirSet from '@/views/EirSets/partials/ModalEirSet'
import SelectSections from '@/components/SelectSections'
import CopySetButton from '@/components/CopySetButton'
import ModalPublishingASet from '@/components/ModalPublishingASet'

export default {
  name: 'EirSetItem',
  components: {
    ModalPublishingASet,
    CopySetButton,
    SelectSections,
    Back,
    ModalEir,
    TabEirs,
    TabParameters,
    ModalParameter,
    ModalEirSet,
  },
  data: () => ({
    tab: 0,
    isLoading: false,
    showVersions: false,
    showImportField: false,
    fileForImport: null,
  }),
  computed: {
    ...mapGetters(['isAdmin', 'isManager']),
    ...mapGetters('eirSets', ['checkIsPublished']),
    ...mapState('eirSets', ['eirSetInfo']),
    ...mapState('eirElements', [
      'eirFilters',
      'eirElements',
      'totalEirElements',
    ]),
    ...mapState('parameters', [
      'parameters',
      'paramFilters',
      'totalParameters',
    ]),
    ...mapState('sections', ['sections']),
    totalElements: (vm) => vm.totalEirElements ?? vm.eirElements?.length ?? 0,
    totalParams: (vm) => vm.totalParameters ?? vm.parameters?.length ?? 0,
    setId: (vm) => vm.$route.params.setId,
  },
  async mounted() {
    const tab = this.$route.query.tab

    if (tab) this.tab = +tab

    this.isLoading = true

    await this.fetchSet(this.setId)
    await this.fetchSections({ eir_set: this.setId })
    await this.fetchParams({
      filters: { eir_set: this.setId, limit: 999 },
    })

    this.isLoading = false
  },
  methods: {
    ...mapMutations('eirElements', ['setEirFilters', 'setEirOptions']),
    ...mapActions('eirSets', ['fetchSet', 'importEirSet']),
    ...mapActions('eirElements', ['fetchEirElements']),
    ...mapActions('sections', ['fetchSections']),
    ...mapActions('parameters', ['fetchParams']),
    /**
     * @description Редактирование требования
     * */
    updateEirElement(item) {
      this.$refs.modal.show(item, 'update')
    },
    async fetchRelatedToSections(filterSections) {
      // TODO: нельзя менять стейт не через мутации или экшны!
      this.setLoadingTabs(true)
      this.resetTableOffset()

      if (!filterSections.section__in) {
        delete this.eirFilters.section__in
        delete this.paramFilters.section__in
      }

      this.eirFilters.eir_set = this.setId
      this.paramFilters.eir_set = this.setId
      await this.fetchEirElements({
        filters: { ...this.eirFilters, ...filterSections },
      })
      await this.fetchParams({
        filters: { ...this.paramFilters, ...filterSections },
      })
      this.setLoadingTabs(false)
    },
    resetTableOffset() {
      if (this.$refs.tabEirs?.tableOptions) {
        this.$refs.tabEirs.tableOptions.page = 1
      }
      if (this.$refs.tabParameters?.tableOptions) {
        this.$refs.tabParameters.tableOptions.page = 1
      }
    },
    setLoadingTabs(bool = false) {
      if (this.$refs.tabEirs) {
        this.$refs.tabEirs.isLoading = bool
      }
      if (this.$refs.tabParameters) {
        this.$refs.tabParameters.isLoading = bool
      }
    },
    async importSet() {
      if (!this.fileForImport) return
      if (!confirm('Импортировать требования и параметры из файла?')) return

      this.isLoading = true

      try {
        await this.importEirSet({ setId: this.setId, file: this.fileForImport })

        this.setEirFilters({})
        this.setEirOptions({})

        await this.fetchSet(this.setId)
        await this.fetchSections({ eir_set: this.setId })
        await this.fetchParams({
          filters: { eir_set: this.setId, limit: 20 },
        })
      } catch (e) {
        this.$logger('error', '[eirSets/importEirSet]', e)
      } finally {
        this.showImportField = false
        this.fileForImport = null
        this.tab = 0
        this.isLoading = false
      }
    },
  },
}
</script>
