<template>
  <div>
    <v-data-table
      class="custom-table with-actions"
      loading-text="Загрузка... Пожалуйста, подождите"
      :loading="isLoading"
      :headers="TABLE_HEADERS"
      :items="eirElements"
      :mobile-breakpoint="0"
      :items-per-page="20"
      :hide-default-footer="totalEirElements <= 20"
      :server-items-length="totalEirElements"
      :options.sync="tableOptions"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      sort-desc
      :search="search"
      @click:row="clickRow"
    >
      <template #top>
        <v-text-field
          v-model="search"
          label="Поиск по наименованию"
          class="mt-1"
          outlined
          hide-details
          dense
          style="max-width: 500px"
        />
      </template>

      <template #item.name="{ item }">
        <div :title="item.name">
          {{ item.name | truncate(40) }}
        </div>
      </template>

      <template #item.description="{ item }">
        <div :title="item.description">
          {{ item.description | truncate(40) }}
        </div>
      </template>

      <template #item.updated_at="{ item }">
        {{ item.updated_at | formatDate }}
      </template>

      <template #item.revit_filter="{ item }">
        <v-icon
          :color="item.revit_filter ? 'primary' : 'error'"
          :title="item.revit_filter ? 'Фильтр назначен' : 'Фильтр отсутствует'"
        >
          {{
            item.revit_filter
              ? 'mdi-filter-check-outline'
              : 'mdi-filter-remove-outline'
          }}
        </v-icon>
      </template>

      <template #item.actions="{ item }">
        <div class="lots-of-action d-flex flex-row">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                size="17"
                v-bind="attrs"
                v-on="on"
                @click.stop="onEditClick(item)"
              >
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Редактировать</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="18"
                v-bind="attrs"
                @click.stop="removeItem(item)"
                v-on="on"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { TABLE_HEADERS } from '@/views/EirSetItem/constants'
import debounce from 'lodash/debounce'

export default {
  name: 'TabEirs',
  data: () => ({
    isLoading: false,
    TABLE_HEADERS,
    search: '',
    tableOptions: {},
  }),
  computed: {
    ...mapState('eirElements', [
      'eirElements',
      'totalEirElements',
      'eirFilters',
      'eirOptions',
    ]),
  },
  watch: {
    tableOptions() {
      this.debounceFetch()
    },
    search() {
      if (this.tableOptions.page !== 1) {
        this.tableOptions.page = 1
      } else {
        this.debounceFetch()
      }
    },
  },
  beforeDestroy() {
    this.resetElementsState()
  },
  methods: {
    ...mapMutations('eirElements', ['resetElementsState']),
    ...mapActions('eirElements', ['fetchEirElements', 'deleteEirElement']),
    debounceFetch: debounce(function () {
      this.fetchEirElementsWrapper()
    }, 400),
    async fetchEirElementsWrapper() {
      this.isLoading = true
      await this.fetchEirElements({
        options: this.tableOptions,
        filters: {
          ...this.eirFilters,
          search: this.search,
          eir_set: this.$route.params.setId,
        },
      })
      this.isLoading = false
    },
    clickRow(row) {
      this.$router.push(`/eir-sets/${this.$route.params.setId}/eirs/${row.id}`)
    },
    onEditClick(item) {
      this.$emit('update', item)
    },
    async removeItem(item) {
      if (confirm('Удалить требование?')) {
        this.isLoading = true

        await this.deleteEirElement(item.id)
        await this.$logActionEvent('Требование удалено')
        await this.fetchEirElementsWrapper()

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-data-table.with-actions {
  :deep(td:last-child),
  :deep(th:last-child) {
    width: 0;
    padding: 0;
  }
}
</style>
