export default [
  { text: 'Наименование', value: 'name' },
  { text: 'Описание', value: 'description', sortable: false },
  { text: 'Кол-во параметров', value: 'parameters_count' },
  {
    text: 'Фильтр',
    value: 'revit_filter',
    width: '40px',
  },
  { text: 'Последнее изменение', value: 'updated_at', width: '120px' },
  {
    text: '',
    value: 'actions',
    sortable: false,
  },
]
